@import 'main';
@import 'var';

$white: #fff;

input:-webkit-autofill, 
textarea:-webkit-autofill, 
select:-webkit-autofill{
  background-color: #fff !important;
}

body{
  background-color: #f0f0f0;
}

.login{
  width: 100%;
  min-height: 100%;
  // background-image: url(../images/card_block_bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  // background-color: #ccc;

  &__block{
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -259px 0 0 -374px;
    display: flex;
    cursor: default;

    &__menu{
      width: 41px;
      background-color: $bgcontrols;
      border-radius: 5px 0 0 5px;

      &_pict{

        &_logo{
          width: 41px;
          height: 96px;
          background-color: $light_blue;
          border-radius: 5px 0 0 0;

          & img{
            padding: 36px 6px;
          }

          & a{
            outline: thin;
          }
        }

        &_nav{
          width: 41px;
          height: 422px;
          background-color: $white;
          border-radius: 0 0 0 5px;

          &_first{
            height: 135px;
            border-top: 1px solid $dividing;
            border-bottom: 1px solid $dividing;

            & a{
              outline: thin;

              & img{
                margin: 24px 14px 0;
              }
            }

            &_mail{
              position: relative;
              left: 7px;
              top: -45px;
            }
          }

          &_last{
            height: 135px;
            border-bottom: 1px solid $dividing;

            & a{
              outline: thin;

              & img{
                margin: 24px 14px 0;
              }
            }
          }
        }
      }
    }

    &__slogan{
      width: 291px;
      height: 518px;
      background-color: $main;
      color: $white;


      &__title{
        font-size: 30px;
        text-transform: uppercase;
        line-height: 100%;
        margin-left: 41px;

        &:first-child{
          margin-top: 65px;
        }
      }

      &__subtitle{
        font-size: 14px;
        margin-left: 41px;
        margin-bottom: 0;
        line-height: 145%;

        &:first-child{
          margin-top: 39px;
        }
      }

      &__pict{
        margin: 40px 40px 0;
        width: 212px;
        height: 159px;
      }

      &__text{
        font-size: 12px;
        color: $light_blue;
        margin: 47px 27px 0;
      }
    }

    &__section{
      width: 416px;
      height: 518px;
      background-color: $white;
      border-radius: 0 5px 5px 0;

      &__head{
        height: 96px;
        background-color: $bgcontrols;
        border-bottom: 1px solid $dividing;
        border-radius: 0 5px 0 0;

        &_title{
          font-size: 22px;
          color: $main;
          padding-left: 43px;
          padding-top: 60px;
          margin: 0;
        }

        &_pict{
          position: relative;
          left: 318px;
          top: -30px;
        }
      }

      &__body{
        height: 307px;
        
        & .input{
          width: 352px;
          margin-bottom: 33px;
        }
        &__form{
          padding-left: 32px;
          padding-top: 48px;

          &_text{
            font-size: 16px;
            color: $basic_grey;
            padding-left: 8px;
            margin: 0;
          }

          &_name{
            box-sizing: content-box;
            width: 332px;
            border: none;
            border-bottom: 1px solid $main;
            padding: 16px 0 14px 8px;
            color: $basic_grey;
            font-size: 14px;
            background-image: url(../images/arrow.svg);
            background-repeat: no-repeat;
            // background-position: 395px 24px;
            margin-bottom: 52px;

            &::placeholder{
              color: $passive_grey;
            }

            &:hover{
              box-sizing: content-box;
              outline: thin;
              border-bottom: 5px solid $main;
              box-sizing: content-box;
              margin-bottom: 48px;
            }

            &:focus{
              outline: thin;
            }

            &:active{
              box-sizing: content-box;
              outline: thin;
              border-bottom: 5px solid $main;
              box-sizing: content-box;
              margin-bottom: 48px;
            }
          }

          &_pass{
            width: 332px;
            border: none;
            border-bottom: 1px solid $main;
            padding: 16px 0 14px 8px;
            color: $basic_grey;
            font-size: 14px;
            margin-bottom: 52px;

            &::value{
              color: $passive_grey;
            }

            &:hover{
              outline: thin;
              border-bottom: 5px solid $main;
              margin-bottom: 48px;
            }

            &:focus{
              outline: thin;
            }

            &:active{
              outline: thin;
              border-bottom: 5px solid $main;
              margin-bottom: 48px;
            }
          }

          &_radio{
            display: block;
            float: left;
            height: 36px;
            width: 104px;
            position: relative;
            left: 249px;
            top: -30px;
            border-radius: 16px;
            background-color: $main;

            & input{
              height: 28px;
              width: 83px;
              left: 0;
              margin: 0;
              opacity: 0;
              padding: 0;
              position: absolute;
              z-index: 2;
              cursor: pointer;
            }

            & input:checked~.switch{
              left: 6px;
            }

            & input:checked~.switch{
              left: 75px;
            }

            & input:checked~.switch{
              z-index: 0;
            }

            &_switch{
              background: $white;
              display: block;
              float: left;
              height: 24px;
              width: 24px;
              border-radius: 50%;
              position: relative;
              top: 6px;
              left: 6px;
              z-index: 1;
              transition: left .5s ease;
            }
          }

          &_btn{
            width: 208px;
            height: 38px;
            position: relative;
            left: 41px;
            top: 67px;
            text-transform: uppercase;
            color: $white;
            background-color: $main;
            border: none;
            border-radius: 22px;
            background-image: url(../images/key.svg);
            background-repeat: no-repeat;
            background-position: 10% 50%;
            outline: thin;
          }
        }
      }

      &__footer{
        height: 115px;
        background-color: $bgcontrols;
        border-top: 1px solid $dividing;
        border-radius: 0 0 5px 0;

        &_text{
          width: 180px;
          font-size: 13px;
          padding: 44px 0 0 44px;
          margin: 0;

          & a{
            text-decoration: none;
            color: $main;
            outline: thin;

            &:hover{
              border-bottom: 1px solid $main;
            }

            &:active{
              border-bottom: 1px solid $main;
            }
          }
        }
      }
    }
  }
}

.form-group{
  & input{
    margin-bottom: 42px;
    margin-top: 10px;
    width: 416px;
  }
}
.login__block__section__body__form_radio input:checked~.login__block__section__body__form_radio_switch{
  left: 6px;
}
.login__block__section__body__form_radio input~:checked~.login__block__section__body__form_radio_switch{
  left: 75px;
}
.login__block__section__body__form_radio input:checked{
  z-index: 0;
}