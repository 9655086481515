@import 'var';
@import '../plugins/normalize';
@import 'helper';
@import '../plugins/tooltip';
@import '../plugins/modal';
@import '../plugins/waves';

*{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*::selection{
    background-color: $main;
    color: #fff;
}

*::-moz-selection{
    background-color: $main;
    color: #fff;
}

a:active,
a:focus,
input:active,
input:focus,
textarea:active,
textarea:focus,
button:active,
button:focus,
label:active,
label:focus{
    outline: none !important;
    outline-style: none !important;
    -moz-outline-style: none !important;
    text-decoration: none !important;
}

html, body{
    height: 100%;
    line-height: 140%;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #3A424F;
    background-color: #ffffff;
}

h1{font-size: 24px;}
h2{font-size: 22px;}
h3{font-size: 20px;}
h4{font-size: 18px;}

h1, h2, h3, h4, h5, h6{
    font-weight: 400;
    margin: 0 0 30px;
    line-height: 140%;
}

input,
select,
textarea {
    font-family: 'Roboto', sans-serif;
}

p{
    margin: 0 0 16px;
}

img{
    line-height: 100%;
    max-width: 100%;
    display: block;
}

a, a:hover{
    color: $main;
    text-decoration: none;
}

ul{
    list-style-type: disc;
    list-style-position: inside;
    display: block;
    margin: 16px 0;
    padding: 0;
}

ol{
    list-style-type: decimal;
    list-style-position: inside;
    display: block;
    margin: 16px 0;
    padding: 0;
}

b, strong{
    font-weight: bold;
}

i{
    font-style: italic;
}

table{
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 12px 0 12px;
    width: 100%;
}

table th{
    font-weight: bold;
}

table th, table td{
    border: 1px solid #ccc;
    padding: 8px;
}

::-webkit-input-placeholder {
    color: #BFC5D1;
    opacity: 1 !important;
}

:-moz-placeholder {
    color: #BFC5D1;
    opacity: 1 !important;
}

::-moz-placeholder {
    color: #BFC5D1;
    opacity: 1 !important;
}

:-ms-input-placeholder {
    color: #BFC5D1;
    opacity: 1 !important;
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.mb_20{
    margin-bottom: 20px;
}

.w_300{
    width: 300px;
}

.w_800{
    width: 800px;
}

.h_240{
    height: 240px;
}

.df{
    display: flex;

}

section{
    display: flex;
    justify-content: space-between;
    min-height: 100%;
    padding-right: 260px;

    main{
        background-color: $background;
        padding: 30px;
        flex: 0 0 100%;
        width: 100%;
    }
}

.icon-btn{
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin-right: 20px;

    &:after{
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        z-index: 9;
        border: 0;
        border-radius: 50%;
        transition: $transition;
    }
    &:hover:after{
        width: 38px;
        height: 38px;
        border: 1px solid #80A8FF;
        transition: $transition;
    }

    .material-icons{
        font-size: 24px;
        align-self: center;
    }

    &_text{
        white-space: nowrap;
        justify-content: flex-start;
        align-items: center;
        width: auto;

        span{
            padding-left: 10px;
        }

        &:after{
            left: 12px;
        }
    }
}

.dropdown-wrap{
    position: relative;
    &.vis{
        .dropdown{
            opacity: 1;
            visibility: visible;
            transform: translateX(0) translateY(0);
        }

    }
}

.dropdown{
    display: block;
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: absolute;
    background: #fff;
    z-index: 100;
    right: -7px;
    top: 50px;
    box-shadow: 0 8px 16px 0 rgba(50,113,254,.3);
    opacity: 0;
    visibility: hidden;
    transform: translateX(0) translateY(20px);
    transition: $transition;

    &:after{
        display: block;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-bottom: 12px solid #fff;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        top: -12px;
        right: 10px;
    }

    &-item{
        height: 36px;
        padding: 0 0 0 10px;
        display: flex;
        align-items: center;
        color: #a8b0c3 !important;
        transition: $transition;
        font-size: 14px;

        &:hover{
            color: #FFF;
        }

        .material-icons{
            font-size: 15px;
            color: #a8b0c3 !important;
            margin-right: -4px;
        }

        &_checked{
            color: #3A424F !important;

            .material-icons{
                color: #3A424F !important;
            }
        }
        &_disabled{
            cursor: not-allowed;

            .material-icons{
                opacity: 0;
            }

            span{
                color: #DEE2ED;
            }
        }
    }

    .dropdown_radio{
       margin: 0;
    }
}

.slimScrollBar{
    opacity: 1 !important;
}

.user-pic--list{
    display: inline-block;
    width: 34px;
    height: 34px;
    overflow: hidden;
    border: 2px solid $main;
    border-radius: 50%;
    margin-left: -8px;

    &:first-child{
        margin-left: 8px;
    }
}


.textarea{

    &-wrap{
        border: 1px solid #E7EAF2;
        border-bottom: 1px solid $main;

        &.active{

            .textarea{
                box-shadow: inset 0px -3px 0 0 $main;
            }
        }
        .slimScrollDiv{

            height: 100%;

            &:after{
                display: block;
                position: absolute;
                content: '';
                bottom: 20px;
                left: 0;
                width: 100%;
                height: 30px;
                background: linear-gradient(to bottom, rgba(30,87,153,0) 0%,rgba(255,255,255,1) 100%);
            }
        }
        &_editor{
            .slimScrollDiv{
                height: calc(100% - 48px);
            }
        }
        &__panel{
            height: 48px;
            background-color: #F5F6FA;
            border-bottom: 1px solid #E7EAF2;
            display: flex;
            align-items: center;
            padding: 0 0 0 14px;
            justify-content: space-between;

            div{
                display: flex;
            }

            &-item{
                cursor: pointer;
                transition: $transition;
                width: 30px;
                height: 30px;

                &:hover, &.active{
                    color: $main;
                }
                .material-icons{
                    font-size: 20px;
                }
            }

            .icon-btn_fullheight{
                height: 48px;
                width: 48px;
                &:after{
                    display: none;
                }
                &:hover{
                    background: $main;
                    color: #fff;
                    border-radius: 0;
                }
            }
        }

    }
}

.modal-backdrop{
    //opacity: .8 !important;
    background-color: rgb(240, 241, 248);
    transition: opacity .15s linear;
    &.fade {
        opacity: 0;
    }
    &.in {
        opacity: .5;
    }
}

.modal.fade.in {
    opacity: 1;
    transition: opacity .15s linear;
}
.modal.fade {
    opacity: 0;
    transition: opacity .15s linear;
}

.modal-dialog{
    width: 400px;
    margin: 100px auto 0;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 8px 16px rgba(50, 113, 254, 0.3);

    &__header{
        position: relative;
        text-align: left;
        color: #3A424F;
        font-size: 21px;
        font-weight: 300;
        padding: 30px 30px 0 30px;
        margin-bottom: 20px;

        .icon-btn{
            position: absolute;
            top: 20px;
            right: 10px;
            cursor: pointer;
            color: #80A8FF;
            margin-right: 0;
        }
    }
    &__body{
        &_chooselist{
            padding: 0 19px 30px 28px;
        }
    }
}

.chooselist{

    max-height: 185px;
    margin-bottom: 40px;

    &__item{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        padding-right: 30px;
        color: #BFC5D1;
        transition: $transition;

        &_checked{
            color: #3A424F;
            .chooselist__item-input{
                color: #3A424F;
            }
        }

        &:last-child{
            margin: 0;
        }

        &_title{
            justify-content: flex-end;
            color: #BFC5D1;
            font-size: 11px;
            font-weight: 400;
            text-transform: uppercase;
        }

        &-check{
            flex: 0 0 184px;
            display: flex;
            align-items: center;
            cursor: pointer;
            max-width: 240px;
            padding-right: 5px;

            span{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: block;
            }
            .material-icons{
                margin-right: 8px;
                font-size: 16px;
            }
        }

        &-input{
            height: 24px;
            border: 1px solid #DEE2ED;
            width: 50px;
            padding: 0 8px;
            text-align: right;
            color: #BFC5D1;
            transition: $transition;
        }
    }
}

.search{
    width: 296px;
    margin: 0 auto 15px;
    position: relative;

    &-input{
        height: 36px;
        border: 0;
        border-bottom: 1px solid #80a8ff;
        padding: 0;
        width: 100%;
        padding-right: 30px;
    }
    .material-icons{
        position: absolute;
        top: 6px;
        right: 3px;
        color: #80a8ff;
        cursor: pointer;
    }
}

.button{
    width: 100%;
    height: 48px;
    line-height: 48px;
    background-color: $main;
    border-radius: 3px;
    border: 0;
    color: #fff;
    cursor: pointer;
    transition: $transition;
    text-align: center;
    display: inline-block;

    &:hover{
        background: #436cd8;
        color: #fff;
    }
}

.grid-item .form-group{
    margin-bottom: 0;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: left;

    .select2-container{
        width: 100%;
        height: 30px;

        &.input{
            padding: 0;
            border: 0;
        }

        .select2-choice{
            height: 30px;
            border: 0;
            color: #3A424F;
            font-weight: 100;
            box-shadow: 0 0 0 !important;
            background-color: #f5f6fa;
            background-image: none;

            > .select2-chosen{
                height: 30px;
                line-height: 29px;
            }

            .select2-arrow{
                background-image: none;
                border-left: 0;
                background-color: #f5f6fa;
                width: 30px;

                b{
                    background-size: 107px 48px !important;
                }
            }
        }
    }

    .select2-container-active .select2-choice,
    .select2-container-active .select2-choices{
        border: 1px solid $main;
    }

    .select2-dropdown-open {
        .select2-choice {
            .select2-arrow{
                b{
                    background-position: -32px 1px;
                }
            }
        }
    }

    .select2-drop-active{
        border: 1px solid $main;
    }
}

.select2-results{

    font-weight: 100 !important;

    .select2-result-label{
        font-weight: 100 !important;
    }

}

.list-view .empty{
    text-align: center;
}

.gray{
    color: #b9b9b9;
}


@-webkit-keyframes spCircRot {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(359deg); }
}
@keyframes spCircRot {
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
}

.blocked{
    position: relative;
    
    &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #fff;
        z-index: 100;
    }

    &:before{
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 32px;
        height: 32px;
        clear: both;
        margin: -32px 0 0 -16px;
        border: 4px solid $main ;
        border-top: 4px solid #fff;
        border-radius: 50%;
        -webkit-animation: spCircRot .6s infinite linear;
        animation: spCircRot .6s infinite linear;
        z-index: 101;
    }
}


/**********************   EFFECTS    ***************************/


@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}






@import 'query';


